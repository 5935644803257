import { createContext, useContext, useEffect, useState } from 'react'
import Http from "../../../@core/http"
const DateContext = createContext()
const httpService = new Http()
export const useDate = () => {
  return useContext(DateContext)
}

export function DateProvider({ children }) {
  const [shift, setShift] = useState('') // New  state
  const [selectedDate, setSelectedDate] = useState(() => {
    // Try to retrieve the selected date from local storage
    const storedDate = localStorage.getItem('selectedDate')
    return storedDate ? new Date(storedDate) : null
  })

  const [isPasswordEntered, setIsPasswordEntered] = useState(() => {
    // Try to retrieve isPasswordEntered status from local storage
    return localStorage.getItem('isPasswordEntered') === 'true'
  })
  const [checkDayend, setCheckDayend] = useState('')

  const updateDate = (date) => {
    setSelectedDate(date)
    // Store the selected date in local storage
    localStorage.setItem('selectedDate', date)
  }

  const updateShift = () => {

    setShift(3)
  }
  const fetchShiftData = async () => {
    const params = { curDate:selectedDate }


    const response = await httpService.http("get", "check-Day-End", params, "", "")

    setCheckDayend(response)
    // console.log('false', response)
    const settingPassword = localStorage.getItem('settingPassword')
    if (settingPassword) {
      // If settingPassword is true, set isPasswordEntered to false
      localStorage.setItem('isPasswordEntered', 'false')
    } else {
      // Check dayend status and set isPasswordEntered accordingly
      if (response.dayend === '') {
        localStorage.setItem('isPasswordEntered', 'false')
      } else {
        localStorage.setItem('isPasswordEntered', 'true')
      }
    }

  }


  useEffect(() => {
    fetchShiftData()

  }, [selectedDate])

  useEffect(() => {
    updateShift()
    // Clean up local storage when the component unmounts
    return () => {
      localStorage.removeItem('selectedDate')
    }
  }, [])

  return (
    <DateContext.Provider value={{ selectedDate, updateDate, updateShift, shift, isPasswordEntered, setIsPasswordEntered, checkDayend, fetchShiftData }}>
      {children}
    </DateContext.Provider>
  )
}
